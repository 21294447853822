const siteUrl = 'https://www.carolinacustomvehicles.com/'

module.exports = {
  /* Your site config here */
  siteMetadata: {
    title: 'Nissan',
    siteUrl:'https://www.carolinacustomvehicles.com/',
    site_id: '660afde1c5a5e7b81fb23c7d',
    api_base_url: 'https://api.dealermasters.com:4500',
    lead_url: 'https://leads.dealermasters.com/leads',
    master_sites: [],
    autocheck_url: 'https://n1u6ywp1bf.execute-api.us-east-2.amazonaws.com/default/AutoCheckFastLink',
      quickgenAPI: 'https://dev.mxsapi.com',
      imageBaseURL: 'https://images.mxssolutions.com'
  },
  plugins: [
    'gatsby-plugin-react-helmet',
    {
      resolve: "gatsby-plugin-force-trailing-slashes"
    },
    {
      resolve: 'gatsby-plugin-advanced-sitemap',
      options: {
      },
    },
    {
      resolve: "gatsby-plugin-emotion"
    },
    {
      resolve: "gatsby-transformer-json"
    },
    {
      resolve: "gatsby-plugin-sass",
      options: {
        cssLoaderOptions: {
          camelCase: false,
        },
      },
    },
    {
      resolve: "gatsby-plugin-react-svg",
      options: {
        rule: {
          include: /\.inline\.svg$/
        }
      }
    },
    {
      resolve: `gatsby-source-filesystem`,
      options: {
        name: `src`,
        path: `${__dirname}/src/`
      }
    },
    {
      resolve: 'gatsby-plugin-s3',
      options: {
        bucketName: 'mxs-39525cb5-1699-4a1d-a14c-2e2ef529a110-prodsite'
      }
    },
    `gatsby-transformer-json`,
    {
      resolve: require.resolve('./plugins/gatsby-source-dynamodb-query'),
      options: {
        typeName: 'inventory',
        region: 'us-east-2',
        tableName: 'AdjustedInventory',
        dealerId: ['22765462']//126024
      }
    },
    {
      resolve: 'gatsby-plugin-manifest',
      options: {
        icon: 'static/favicon.png'
      }
    },{
      resolve: 'gatsby-plugin-robots-txt',
      options: {
        host: 'https://www.carolinacustomvehicles.com/',
        sitemap: 'https://www.carolinacustomvehicles.com//sitemap-pages.xml',
        policy: [{ userAgent: '*', allow: '/' }]
      }
    }
  ]
}